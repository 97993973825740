// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"
import * as bootstrap from "bootstrap"

import "./src/jquery"
import "./src/jquery-ui"
import "@fortawesome/fontawesome-pro/js/all";

import Rails from "@rails/ujs"
Rails.start()

$(function() {
  console.log("hello world")
  $("#dialog").dialog();
  $(document).tooltip();
  $("#tabs").tabs();

})
